import { Injectable } from '@angular/core';
import { NativeAudio } from '@capacitor-community/native-audio';
import { Capacitor } from '@capacitor/core';
import { AppStorage } from 'src/app/shared/app-storage';

export enum Sounds {
  NOTIFICATION = 'noti_2.mp3',
  BACKGROUND_NOTIFICATION = 'noti_1.mp3',
}

@Injectable({
  providedIn: 'root',
})
export class AudioService {
  constructor() {
    let prefix = '';
    if (Capacitor.getPlatform() == 'android') {
      prefix = 'public/';
    }
    NativeAudio.preload({
      assetId: Sounds.NOTIFICATION,
      assetPath: prefix + 'assets/sounds/' + Sounds.NOTIFICATION,
      audioChannelNum: 1,
      isUrl: false,
    });

    NativeAudio.preload({
      assetId: Sounds.BACKGROUND_NOTIFICATION,
      assetPath: prefix + 'assets/sounds/' + Sounds.BACKGROUND_NOTIFICATION,
      audioChannelNum: 1,
      isUrl: false,
    });
  }

  private lastVolume: number;

  public playSound(sound: Sounds) {
    let volume = this.getVolume();
    if (volume != this.lastVolume) {
      this.lastVolume = volume;
      NativeAudio.setVolume({
        assetId: Sounds.NOTIFICATION,
        volume,
      });
      NativeAudio.setVolume({
        assetId: Sounds.BACKGROUND_NOTIFICATION,
        volume,
      });
    }

    NativeAudio.play({
      assetId: sound,
    });
  }

  private getVolume(): number {
    let volume = AppStorage.getItem('volume');
    if (volume) {
      return parseFloat(volume);
    } else {
      return 1;
    }
  }
}

// https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Using_Web_Audio_API
// https://codepen.io/kslstn/pen/pagLqL

// We can not use HTMLAudioElement because it stops the the media players on iOS, iPhone
// so instead we create an AudioContext with AudioBufferSourceNode

/*
const audioContext = new AudioContext();
const gainNode = audioContext.createGain();
gainNode.gain.value = 1;

let bufferedSounds: { [key in Sounds]?: AudioBuffer } = {};

let loadSoundAsBuffer = (sound: Sounds) => {
  return window
    .fetch(sound)
    .then((response) => response.arrayBuffer())
    .then((arrayBuffer) => audioContext.decodeAudioData(arrayBuffer));
};

Object.values(Sounds).forEach((s: Sounds) => {
  loadSoundAsBuffer(s).then((arrayBuffer) => {
    bufferedSounds[s] = arrayBuffer;
  });
});

let play = (sound: Sounds) => {
  let audioBuffer = bufferedSounds[sound];
  if (audioBuffer) {
    let audioSource = audioContext.createBufferSource();
    audioSource.buffer = audioBuffer;
    audioSource.connect(audioContext.destination);
    audioSource.start();
  } else {
    console.warn('sound did not loaded yet');
  }
};
*/
